.container:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 1000px rgba(94, 94, 94, 0.5);
    filter: blur(5px);
    -webkit-filter: blur(5px);
    overflow: auto;
}

.line {
    background: white;
    margin: 2rem;
    align-content: center;
}

  .container {
    background: url("/src/assets/background.jpg") no-repeat;
    background-attachment: fixed; 
    background-size: cover;   
    overflow: auto;

  }

  .title{
      color: rgb(218, 218, 218);
      text-align: center;
      margin-top: -50%;
      margin-bottom: -1rem;
      font-size: 5rem;
      -webkit-text-stroke: 0.5px black;

  }

  .sub{
    color: rgb(218, 218, 218);
    text-align: center;
    font-size: 3rem;
    -webkit-text-stroke: 0.5px black;

  }

  .comp{
    color: rgb(218, 218, 218);
    text-align: center;
    font-size: 1.5rem;
    -webkit-text-stroke: 0.5px black;

  }

  .cont2{
    text-align: center;
  }

  .link{
    color: rgb(218, 218, 218);
    font-size: 1.5rem;
    text-decoration: none;
    -webkit-text-stroke: 0.5px black;

  }

  .link:hover{
    color: #a28089;
    font-size: 1.5rem;
    text-decoration: none;
  }

  
  .Loading {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 10px;
    background: #f1f1f1;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    border-radius: 4px;
    overflow: hidden;
  }
  
  .Loading:after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    animation: load 5s forwards;
    background: #a28089;

  }
  
  @keyframes load {
    0% {
        width: 0;
      }
      
      25% {
        width: 25%;
      }
      
      50% {
        width: 25%;
      }
      
      75% {
        width: 25%;
      }
      
      100% {
        width: 25%;
      }
    
    
  }
  

@media only screen and (max-width: 400px) {
    .title{
        font-size: 2rem;
        margin-top: 0;
    }
  
    .sub{
      font-size: 1rem;
    }
  }

  @media only screen and (max-height: 665px) {
    .title{
        text-align: center;
        margin-top: 0;
    }

  }

  @media only screen and (max-width: 570px) {

    .title{
        margin-top: -50%;
        margin-bottom: -1rem;
        font-size: 3rem;
    }
  
    .sub{
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 700px) {

    .title{
      -webkit-text-stroke: 0.8px black;

    }
  
    .sub{
      -webkit-text-stroke: 0.8px black;

    }

    .comp{
      -webkit-text-stroke: 0.8px black;
    }
  }